const routes = {
    MainPage: '/',
    Termscondition:'/TermsConditions',
    PrivacyPolicy:'/PrivacyPolicy',
    Refundcancellation:'/Refundcancellation',
    AboutUs: '/Aboutus',
    Support: '/support',

}
export default routes;
