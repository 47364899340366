import react, { Suspense } from 'react';
import OurRoutes from './Routes';
import * as Routers from './Routers';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function AppRouter() {
    return (
        <Router>
            <Suspense>
                <Routes>
                    <Route path={OurRoutes.MainPage} element={<Routers.MainPage />}>
                        <Route index element={<Routers.HomePage />} />
                        <Route path={OurRoutes.Termscondition} element={<Routers.Termscondition />} />
                        <Route path={OurRoutes.PrivacyPolicy} element={<Routers.PrivacyPolicy />} />
                        <Route path={OurRoutes.Refundcancellation} element={<Routers.Refundcancellation />} />
                        <Route path={OurRoutes.AboutUs} element={<Routers.AboutUs />} />
                        <Route path={OurRoutes.Support} element={<Routers.Support />} />
                    </Route >
                </Routes>
            </Suspense>
        </Router>
    );
}

export default AppRouter;